import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { HeroLogo } from "@components/svg/HeroLogo"
import { defineBlock, EditableText } from "eddev/blocks"

export const meta: BlockMeta = {
  title: "Home Image",
}

export default defineBlock("home/image", (props) => {
  return (
    <BlockWrapper toolbarState="course-progress">
      <BlockItem className="bg-beige">
        <div className="flex justify-center items-center w-full">
          <ResponsiveImage
            {...props.image}
            objectFit="cover"
            aspect={false}
            className="absolute w-full h-full [&_img]:!h-full left-0 top-0 block"
          />
          <div className="w-full relative h-[55vw] md:h-[30vw]" />
        </div>
      </BlockItem>
    </BlockWrapper>
  )
})